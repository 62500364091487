<template>
  <core-section id="who-i-am">
    <abstract-1 />

    <core-heading>Who I am.</core-heading>

    <v-col
      cols="12"
      xl="7"
    >
      <v-row justify="space-between">
        <v-col
          cols="12"
          md="6"
        >
          <core-text class="mb-0">
            <p
              class="summary-text"
              v-text="schema.basics.summary"
            />
<!--            <div>-->
<!--              <v-img-->
<!--                :src="require('@/assets/signature.png')"-->
<!--                class="ml-auto"-->
<!--                width="250"-->
<!--              />-->
<!--            </div>-->
          </core-text>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-row align="center">
            <v-col
              cols="3"
              class="text-uppercase primary--text font-weight-medium"
            >
              <div
                class="mb-3"
                v-text="'Location'"
              />
              <div
                class="mb-3"
                v-text="'Email'"
              />
              <div
                class="mb-3"
                v-text="'Phone'"
              />
              <!-- <div v-text="'Discord'" /> -->
            </v-col>
            <v-col
              cols="9"
              class="subheading text-no-wrap"
            >
              <div
                class="mb-3"
                v-text="'United States'"
              />
              <div
                class="mb-3 text-truncate"
                v-text="schema.basics.email"
              />
              <div
                class="mb-3"
                v-text="schema.basics.phone"
              />
              <!-- <div>@j.smith#3992</div> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      class="hidden-sm-and-down text-right text-uppercase"
      cols="5"
    >
      <div
        v-if="false"
        class="text-h4"
        v-text="schema.basics.label"
      />

      <span
        v-if="false"
        class="primary--text text-h5 font-weight-light"
        v-text="schema.basics.name"
      />

<!--      <v-img-->
<!--        v-show="this.$vuetify.breakpoint.lgAndUp"-->
<!--        :src="require('@/assets/profile.png')"-->
<!--        contain-->
<!--        height="600"-->
<!--        width="500"-->
<!--        style="right: 40px; position: absolute; top: 300px;"-->
<!--      />-->
    </v-col>
  </core-section>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'WhoIAm',

    components: {
      Abstract1: () => import('@/components/Abstract1'),
    },

    computed: {
      ...mapState('app', ['schema']),
    },
  }
</script>

<style lang="sass">
  .v-expansion-panels > li
    border-top: 1px solid rgba(#fff, .12) !important

  .summary-text
    z-index: 99!important
</style>
